<template>
  <div>
    <div>
      <h1>{{ $t('forgotPassword.requestCode.title') }}</h1>
      <h3>{{ $t('forgotPassword.requestCode.desc') }}</h3>
    </div>
    <br>
    <v-form
      @submit.prevent="submit"
      v-model="isFormValid">
      <!-- EMAIL -->
      <v-text-field
        id="request-code-email"
        type="text"
        inputmode="email"
        v-model="email"
        :rules="rules.email"
        autocomplete="email"
        required
        :label="`${$t('auth.email')} *`"
        outlined
        :hint="email === '' ? $t('auth.sign_in.email_hint') : ''"
        validate-on-blur
        ref="request-code-email"
        @input="checkValidation('request-code-email')"
      ></v-text-field>

      <v-btn
        block
        x-large
        color="secondary"
        type="submit"
        :loading="submitting"
        :disabled="!isFormValid"
      >
        {{ $t('forgotPassword.requestCode.submit') }}
      </v-btn>
    </v-form>
    <br>
    <!-- Forgot Password -->
    <div class="text-uppercase text-center">
      <router-link to="/" class="defaultText--text">
        {{ $t('forgotPassword.requestCode.back') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { email } from '@/utils/validators';

export default {
  name: 'RequestCode',
  data() {
    return {
      email: this.$store.state.forgotPassword.data.email,
      isFormValid: true,
      submitting: false,
      rules: {
        email,
      },
    };
  },
  methods: {
    async submit() {
      this.submitting = true;
      try {
        await this.$store.dispatch('forgotPassword/requestCode', this.email);
      } catch (error) {
        this.$notify.error(error.message);
      } finally {
        this.submitting = false;
      }
    },
    checkValidation(refName) {
      if (!this.isFormValid) {
        this.$refs[refName].validate();
      }
    },
  },
};
</script>
